import React from "react";

import onlineTabloIcon from "../../../../../assets/icons/newDesign/onlineTabloIcon.svg"
import Container from "../../../ui/container";
import { IframeWrapper, Iframe, FrameBlock, TitleOverlay, IframeOverlay, DesktopHideBlock, MobileOverlayTitle } from "./components";
import TitleUI from "../../../ui/titleUI";
import tablo from "../../../../../assets/icons/iconsForTitle/airport.svg"

const YandexIframe = () => {
	const iframe = `
    <iframe frameborder="0" style="-moz-box-shadow: 0 2px 3px rgba(0, 0, 0, 0.5);
    -webkit-box-shadow: 0 2px 3px rgba(0, 0, 0, 0.5);
    box-shadow: 0 2px 3px rgba(0, 0, 0, 0.5);
    overflow: hidden;
    border: 0;
    width: 800px;"
    src="https://rasp.yandex.ru/informers/station/9623534/?size=15&amp;color=6&amp;type=tablo"
    >
    </iframe>`;


	return (
		<IframeWrapper>
			<Container fixed>
        <TitleUI as="h2" textTransform="uppercase" icon={tablo} position="center">Онлайн табло аэропорта Душанбе</TitleUI>
				<FrameBlock>
          <TitleOverlay />
          <MobileOverlayTitle />
					<Iframe flex dangerouslySetInnerHTML={{ __html: iframe ? iframe : null }} />
          <IframeOverlay />
          <DesktopHideBlock />
				</FrameBlock>
			</Container>
		</IframeWrapper>
	);
};

export default YandexIframe;
